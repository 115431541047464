import React from "react"
import { graphql, Link } from "gatsby"
import { Container } from "react-grid-system"
import { Box } from "@chakra-ui/core"

import { Button, Hero, SEO, SliceZone } from "../components"

const PostTemplate = ({ data: { post } }) => {
  return (
    <>
      <SEO seo={post} />
      <Hero
        text={post.title}
        primary
        small
        fluid
        image={post?.mainImage?.asset.fluid}
      />
      <SliceZone allSlices={post.body} />
      <Box my={{ base: "20", lg: "32" }}>
        <Container>
          <Button as={Link} to="/blog">
            Zurück zur Übersicht
          </Button>
        </Container>
      </Box>
    </>
  )
}

export const query = graphql`
  query Post($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      title
      mainImage {
        asset {
          url
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      seo {
        description
        title
      }
      body {
        ... on SanityBlockBody {
          _key
          _type
          _rawBlock(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityBlockImage {
          _key
          _type
          direction
          image {
            asset {
              url
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          _rawText
        }
        ... on SanityQuote {
          _key
          _type
          _rawBlock(resolveReferences: { maxDepth: 10 })
          direction
        }
      }
    }
  }
`

export default PostTemplate
